import type { Component } from 'vue'
// @ts-expect-error virtual file
import componentImports from '#build/components.dynamic.mjs'
import type { ComponentName, ComponentType } from '#build/components.dynamic'

export interface UseDynamicComponentOptions<
  TDefault extends ComponentName | undefined,
> {
  defaultComponent?: TDefault
  debug?: boolean
}

type GetComponentReturn<
  TComponent extends ComponentName | undefined,
  TDefault extends ComponentName | undefined,
> = TComponent extends ComponentName
  ? ComponentType<TComponent>
  : TDefault extends ComponentName
    ? ComponentType<TDefault>
    : never

const _componentCache = new Map<string, Component>()

export const useDynamicComponent = <
  TDefault extends ComponentName | undefined = undefined,
>(
  options: UseDynamicComponentOptions<TDefault> = {},
) => {
  const { defaultComponent: defaultComponentName, debug = false } = options

  const hasDefaultComponent =
    !!defaultComponentName && defaultComponentName in componentImports

  if (defaultComponentName && !hasDefaultComponent) {
    throw new Error(`Default component ${defaultComponentName} doesn't exist`)
  }
  function getComponent(): GetComponentReturn<undefined, TDefault>
  function getComponent<T extends ComponentName | undefined>(
    name: T,
  ): GetComponentReturn<T, TDefault>
  function getComponent(name?: ComponentName): ComponentType<any> {
    const resolveComponentName = (name?: string): string => {
      if (!name) {
        // If no path is provided, it means the default component should be used
        if (hasDefaultComponent) {
          return defaultComponentName as string
        } else {
          throw new Error(
            'Name is required, either provide a component or a default component',
          )
        }
      }

      const hasComponent = name in componentImports

      // Check if the custom component exists
      if (hasComponent) {
        return name
      }

      // Fallback to the default component if the custom component doesn't exist
      if (hasDefaultComponent) {
        if (debug) {
          console.warn(
            `Component ${name} doesn't exist, falling back to default component ${defaultComponentName}`,
          )
        }

        return defaultComponentName as string
      }

      // If neither custom nor default component exists, throw an error
      throw new Error(
        `Component ${name} doesn't exist, and no default component provided`,
      )
    }

    // Check if the custom component exists, otherwise fallback to the default component
    const component = resolveComponentName(name)

    // Check if the component is cached, otherwise load and cache it
    if (!_componentCache.has(component)) {
      const asyncComponent = getAsyncComponent(component)
      _componentCache.set(component, asyncComponent)
    }

    return _componentCache.get(component)!
  }

  return { getComponent }
}
